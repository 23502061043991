import { Modes } from 'uikitv2/MnemonicGenerator/MnemonicGeneratorUi/types';
import {
  TeeOffer, Offer,
} from 'generated/types';
import { File } from 'hooks/files/types';
import { Storage } from 'hooks/storage/types';

export enum Steps {
  BUILD_ORDER = 'buildOrder',
  CREATE_PASSPHRASE = 'createPassphrase',
  CONFIRM_TRANSACTIONS = 'confirmTransactions',
  ORDER_CREATED = 'orderCreated',
}

export enum ProcessType {
  APPROVE = 'APPROVE',
  WORKFLOW = 'WORKFLOW'
}

export enum ProcessStatus {
  QUEUE = 'QUEUE',
  PROGRESS = 'PROGRESS',
  DONE = 'DONE',
  ERROR = 'ERROR',
  NONE = 'NONE',
}

export interface ProcessValue {
  status: ProcessStatus;
  error?: Error;
}

export interface Process {
  [process: string]: ProcessValue;
}

export enum FieldsBuildOrderForm {
  orderType = 'orderType',
  model = 'model',
  datasets = 'datasets',
  engine = 'engine',
  compute = 'compute',
  lease = 'lease',
}

export interface Slot {
  id: string,
  count: number, // only required for TEE offers. Value Offers must be 0 if id is present
}

export interface Option {
  id: string;
  count: number;
}

export interface Slots {
  slot?: Slot | null;
  options?: Option[] | null;
}

export interface BaseContentInfo {
  id: string;
}

export interface Model extends BaseContentInfo {
  offerId?: string;
  fileId?: string;
  slots?: Slots | null;
}

export interface Dataset extends BaseContentInfo {
  offerId?: string;
  fileId?: string;
  slots?: Slots | null;
}

export interface Engine extends BaseContentInfo {
  offerId?: string;
  slots?: Slots | null;
  configuration?: Record<string, any> | null; // todo
}

export interface Compute extends BaseContentInfo {
  teeOfferId?: string;
  slots?: Slots | null;
}

export enum OrderType {
  fineTune = 'fineTune',
  deploy = 'deploy'
}

export interface BuildOrderForm {
  [FieldsBuildOrderForm.orderType]?: OrderType | null;
  [FieldsBuildOrderForm.model]?: Model | null;
  [FieldsBuildOrderForm.datasets]?: Dataset[] | null;
  [FieldsBuildOrderForm.engine]?: Engine | null;
  [FieldsBuildOrderForm.compute]?: Compute | null;
  [FieldsBuildOrderForm.lease]?: number | null;
}

export type FormContentKey = FieldsBuildOrderForm.model
 | FieldsBuildOrderForm.datasets
 | FieldsBuildOrderForm.engine
 | FieldsBuildOrderForm.compute;

export type FormContent = Pick<BuildOrderForm, FormContentKey>;

export interface ExtendedModel extends Model {
  offer?: Offer | null;
  file?: File | null;
  storage?: Storage | null;
}

export interface ExtendedDataset extends Dataset {
  offer?: Offer | null;
  file?: File | null;
  storage?: Storage | null;
}

export interface ExtendedEngine extends Engine {
  offer?: Offer | null;
}

export interface ExtendedCompute extends Compute {
  teeOffer?: TeeOffer | null;
}

export interface ExtendedFormContent {
  [FieldsBuildOrderForm.model]?: ExtendedModel | null;
  [FieldsBuildOrderForm.datasets]?: ExtendedDataset[] | null;
  [FieldsBuildOrderForm.engine]?: ExtendedEngine | null;
  [FieldsBuildOrderForm.compute]?: ExtendedCompute | null;
}

export type ExtendedFormBuildOrderForm = ExtendedFormContent & Omit<BuildOrderForm, FormContentKey>;

export enum FieldsCreatePassphraseForm {
  phraseGenerated = 'phraseGenerated',
  phraseInput = 'phraseInput',
  phraseMode = 'phraseMode',
  agreement = 'agreement',
}

export interface CreatePassphraseForm {
  [FieldsCreatePassphraseForm.phraseGenerated]?: string;
  [FieldsCreatePassphraseForm.phraseInput]?: string;
  [FieldsCreatePassphraseForm.phraseMode]?: Modes;
  [FieldsCreatePassphraseForm.agreement]?: boolean;
}

export interface Form {
  [Steps.BUILD_ORDER]: BuildOrderForm;
  [Steps.CREATE_PASSPHRASE]: CreatePassphraseForm;
  [Steps.CONFIRM_TRANSACTIONS]: null,
  [Steps.ORDER_CREATED]: null,
}

export interface ExtendedForm {
  [Steps.BUILD_ORDER]: ExtendedFormBuildOrderForm;
  [Steps.CREATE_PASSPHRASE]: CreatePassphraseForm;
}

export interface CreateOrderV2State {
  activeStep: Steps;
  form: Form;
  process: Process;
  submitLoading: boolean;
}

export interface GetExtendedContentProps {
  formContent?: FormContent;
  offers?: (Offer | undefined | null)[];
  teeOffers: (TeeOffer | undefined | null)[];
  files?: File[] | null;
  storages?: Storage[] | null;
}

// we don't use count for offer's slots. set 1 for price calculaction
export enum SLOT_COUNT {
  TEE_OFFER = 1,
  VALUE_OFFER = 0,
  PREVIEW = 1, // for value offers preview
}

export interface CheckAddContentProps {
  offerId?: string;
  fileId?: string;
  teeOfferId?: string;
  formContent?: FormContent;
  field?: FormContentKey;
  slots?: Slots | null;
}

export interface CheckBuildOrderMinMaxTimeResult {
  [FieldsBuildOrderForm.model]: (Offer | null)[];
  [FieldsBuildOrderForm.datasets]: (Offer | null)[];
  [FieldsBuildOrderForm.engine]: (Offer | null)[];
  [FieldsBuildOrderForm.compute]: (TeeOffer | null)[];
}