import { Modes } from 'uikitv2/MnemonicGenerator/MnemonicGeneratorUi/types';
import { CryptoUtils } from 'utils/crypto/CryptoUtils';
import BigNumber from 'bignumber.js';
import {
  Offer,
  TeeOffer,
  TeeOfferOption,
  TeeOfferSlot,
  OfferSlot,
  PriceType,
  TeeOffersAndSLots,
  WorkflowConfigurationValidation,
  TeeOfferWithSlotsAndOptions,
  ValueOfferWithSlotsAndOptions,
} from 'generated/types';
import { OfferType } from '@super-protocol/sdk-js';
import { File } from 'hooks/files/types';
import getConfig from 'config';
import {
  getFixedDeposit, getMultipliedDeposit, getSumDeposit,
} from 'utils/sdk/utils';
import { Requirement } from 'uikitv2/Card/types';
import {
  convertSlotInfo,
  convertOptionData,
  priceTypeMap,
  getDeposit,
  Deposit,
  getMultipleDeposits,
  getMinPriceFromSlots,
  getSlotFromOffer,
  getSlotFromTEEOffer,
  getOptionsFromTEEOffer,
} from 'utils/slots';
import { Storage } from 'hooks/storage/types';
import {
  Steps,
  Form,
  BuildOrderForm,
  FieldsBuildOrderForm,
  CreateOrderV2State,
  OrderType,
  GetExtendedContentProps,
  ExtendedFormContent,
  Model,
  Engine,
  Dataset,
  Compute,
  ExtendedModel,
  ExtendedDataset,
  ExtendedEngine,
  ExtendedCompute,
  FormContent,
  Slots,
  CheckAddContentProps,
  FormContentKey,
  SLOT_COUNT,
  ProcessType,
  Process,
  ProcessStatus,
  FieldsCreatePassphraseForm,
  CreatePassphraseForm,
  ExtendedFormBuildOrderForm,
  CheckBuildOrderMinMaxTimeResult,
} from './types';

export const getInitialBuildOrderForm = (): BuildOrderForm => ({
  [FieldsBuildOrderForm.orderType]: OrderType.deploy,
  [FieldsBuildOrderForm.model]: null,
  [FieldsBuildOrderForm.datasets]: null,
  [FieldsBuildOrderForm.engine]: null,
  [FieldsBuildOrderForm.compute]: null,
  [FieldsBuildOrderForm.lease]: null,
});

export const getInitialCreatePassphraseForm = (): CreatePassphraseForm => ({
  [FieldsCreatePassphraseForm.phraseGenerated]: CryptoUtils.generateMnemonic(),
  [FieldsCreatePassphraseForm.phraseInput]: '',
  [FieldsCreatePassphraseForm.phraseMode]: Modes.own,
});

export const getInitialForm = (): Form => ({
  [Steps.BUILD_ORDER]: getInitialBuildOrderForm(),
  [Steps.CREATE_PASSPHRASE]: getInitialCreatePassphraseForm(),
  [Steps.CONFIRM_TRANSACTIONS]: null,
  [Steps.ORDER_CREATED]: null,
});

export const getInitialProcess = (processList: ProcessType[]): Process => {
  return processList.reduce((acc, process) => ({ ...acc, [process]: { status: ProcessStatus.QUEUE } }), {});
};

export const getInitialState = (): CreateOrderV2State => ({
  activeStep: Steps.BUILD_ORDER,
  form: getInitialForm(),
  process: getInitialProcess([]),
  submitLoading: false,
});

export const getExtendedModel = (
  content?: Model | null,
  offers?: (Offer | undefined | null)[] | null,
  files?: (File | undefined | null)[] | null,
  storages?: (Storage | undefined | null)[] | null,
): ExtendedModel | null => {
  if (!content) return null;
  const { fileId, offerId } = content;
  const file = (fileId ? files?.find((file) => fileId === file?.id) : null) || null;
  const offer = (offerId ? offers?.find((offer) => offerId === offer?.id) : null) || null;
  const storage = (file?.storageId ? storages?.find((storage) => file.storageId === storage?.id) : null) || null;
  return {
    ...content,
    offer,
    file,
    storage,
  };
};

export const getExtendedDataset = (
  content?: Dataset | null,
  offers?: (Offer | undefined | null)[] | null,
  files?: (File | undefined | null)[] | null,
  storages?: (Storage | undefined | null)[] | null,
): ExtendedDataset | null => {
  if (!content) return null;
  const { fileId, offerId } = content;
  const file = (fileId ? files?.find((file) => fileId === file?.id) : null) || null;
  const offer = (offerId ? offers?.find((offer) => offerId === offer?.id) : null) || null;
  const storage = (file?.storageId ? storages?.find((storage) => file.storageId === storage?.id) : null) || null;
  return {
    ...content,
    offer,
    file,
    storage,
  };
};

export const getExtendedEngine = (
  content?: Engine | null,
  offers?: (Offer | undefined | null)[] | null,
): ExtendedEngine | null => {
  if (!content) return null;
  const { offerId } = content;
  const offer = (offerId ? offers?.find((offer) => offerId === offer?.id) : null) || null;
  return {
    ...content,
    offer,
  };
};

export const getExtendedCompute = (
  content?: Compute | null,
  teeOffers?: (TeeOffer | undefined | null)[],
): ExtendedCompute | null => {
  if (!content) return null;
  const { teeOfferId } = content;
  const teeOffer = (teeOfferId ? teeOffers?.find((offer) => teeOfferId === offer?.id) : null) || null;
  return {
    ...content,
    teeOffer,
  };
};

export const getExtendedContent = (props: GetExtendedContentProps): ExtendedFormContent => {
  const {
    formContent, offers, teeOffers, files, storages,
  } = props || {};
  const {
    model, engine, compute, datasets,
  } = formContent || {};
  return {
    [FieldsBuildOrderForm.model]: getExtendedModel(model, offers, files, storages),
    [FieldsBuildOrderForm.datasets]: (datasets || [])
      .map((dataset) => getExtendedDataset(dataset, offers, files) as ExtendedDataset)
      .filter(Boolean) || null,
    [FieldsBuildOrderForm.engine]: getExtendedEngine(engine, offers),
    [FieldsBuildOrderForm.compute]: getExtendedCompute(compute, teeOffers),
  };
};

export const getOffersAndTeeOffersIds = (formContent: FormContent | undefined): {
  offers: string[],
  teeOffers: string[],
} => {
  if (!formContent) return { offers: [], teeOffers: [] };
  const {
    model, engine, compute, datasets,
  } = formContent;
  return {
    teeOffers: compute?.teeOfferId ? [compute.teeOfferId] : [],
    offers: [model, engine, datasets].flat().map((data) => data?.offerId as string).filter(Boolean),
  };
};

export const getFilesIds = (formContent: FormContent | undefined): string[] => {
  if (!formContent) return [];
  const {
    model, datasets,
  } = formContent;
  return [model, datasets].flat().map((data) => data?.fileId as string).filter(Boolean);
};

export const getStorageIdsFromFiles = (files?: File[]): string[] => {
  if (!files?.length) return [];
  return files.map(({ storageId }) => storageId);
};

export const getSelectedSlotEngine = (
  formContent?: FormContent,
  offerId?: string,
): Slots | null => {
  if (!formContent || !offerId) return null;
  const content = formContent[FieldsBuildOrderForm.engine];
  return (content?.offerId === offerId ? content?.slots : null) || null;
};

export const getSelectedSlotCompute = (
  formContent?: FormContent,
  offerId?: string,
): Slots | null => {
  if (!formContent || !offerId) return null;
  const content = formContent[FieldsBuildOrderForm.compute];
  return (content?.teeOfferId === offerId ? content?.slots : null) || null;
};

export const getSelectedSlotModel = (
  formContent?: FormContent,
  offerId?: string,
): Slots | null => {
  if (!formContent || !offerId) return null;
  const content = formContent[FieldsBuildOrderForm.model];
  return (content?.offerId === offerId ? content?.slots : null) || null;
};

export const getSelectedSlotDataset = (
  formContent?: FormContent,
  offerId?: string,
): Slots | null => {
  if (!formContent || !offerId) return null;
  const content = formContent[FieldsBuildOrderForm.datasets];
  return content?.find((item) => item?.offerId === offerId)?.slots || null;
};

export const getSelectedSlot = (
  formContent?: FormContent,
  field?: FormContentKey,
  offerId?: string,
): Slots | null => {
  switch (field) {
    case FieldsBuildOrderForm.engine:
      return getSelectedSlotEngine(formContent, offerId);
    case FieldsBuildOrderForm.model:
      return getSelectedSlotModel(formContent, offerId);
    case FieldsBuildOrderForm.compute:
      return getSelectedSlotCompute(formContent, offerId);
    case FieldsBuildOrderForm.datasets:
      return getSelectedSlotDataset(formContent, offerId);
    default:
      return null;
  }
};

export const checkAddCompute = (
  teeOfferId?: string,
  formContent?: FormContent,
  slots?: Slots | null,
): boolean => {
  if (!slots) {
    return false;
  }
  const { slot, options } = slots || {};
  const { id: slotId, count: slotCount } = slot || {};
  const { slots: slotsFromContent, teeOfferId: teeOfferIdFromContent } = formContent?.[FieldsBuildOrderForm.compute] || {};
  const slotIdFromContent = slotsFromContent?.slot?.id;
  const slotCountFromContent = slotsFromContent?.slot?.count;
  const optionsFromContent = slotsFromContent?.options;
  return teeOfferId === teeOfferIdFromContent
    && slotIdFromContent === slotId
    && slotCountFromContent === slotCount
    && (
      !optionsFromContent?.length
      || optionsFromContent?.every(({ id, count }) => options?.find((option) => option?.id === id && option?.count === count))
    );
};

export const checkAddModel = (
  offerId?: string,
  fileId?: string,
  formContent?: FormContent,
): boolean => {
  if (!offerId && !fileId) return false;
  const content = formContent?.[FieldsBuildOrderForm.model];
  return (!!content?.offerId && content.offerId === offerId) || (!!content?.fileId && content.fileId === fileId);
};

export const checkAddEngine = (
  offerId?: string,
  formContent?: FormContent,
): boolean => {
  if (!offerId) return false;
  const content = formContent?.[FieldsBuildOrderForm.engine];
  return !!content?.offerId && content.offerId === offerId;
};

export const checkAddDataset = (
  offerId?: string,
  fileId?: string,
  formContent?: FormContent,
): boolean => {
  if (!offerId && !fileId) return false;
  const content = formContent?.[FieldsBuildOrderForm.datasets];
  return !!content?.find((item) => {
    return (!!item?.offerId && item.offerId === offerId) || (!!item?.fileId && item.fileId === fileId);
  });
};

export const checkAddContent = (props: CheckAddContentProps): boolean => {
  const {
    formContent, offerId, field, slots, fileId, teeOfferId,
  } = props;
  switch (field) {
    case FieldsBuildOrderForm.compute:
      return checkAddCompute(teeOfferId, formContent, slots);
    case FieldsBuildOrderForm.datasets:
      return checkAddDataset(offerId, fileId, formContent);
    case FieldsBuildOrderForm.model:
      return checkAddModel(offerId, fileId, formContent);
    case FieldsBuildOrderForm.engine:
      return checkAddEngine(offerId, formContent);
    default:
      return false;
  }
};

export const getFieldBuildOrderdByOfferType = (offerType?: OfferType): FormContentKey | FormContentKey | undefined => {
  switch (offerType) {
    case OfferType.Data:
      return FieldsBuildOrderForm.datasets;
      // todo data offer -> dataset
    case OfferType.Solution:
      return FieldsBuildOrderForm.engine;
    case OfferType.TeeOffer:
      return FieldsBuildOrderForm.compute;
    default:
      return undefined;
  }
};

export const getOfferPriceBySlots = (
  selectedSlots?: Slots | null,
  slotsData?: (TeeOfferSlot | OfferSlot)[],
  optionsData?: TeeOfferOption[],
): { type: string; sum: string; }[] => {
  if (!selectedSlots?.slot?.id || !slotsData?.length) return [];
  const { options, slot } = selectedSlots || {};
  const foundOptions = (optionsData || []).reduce((acc, { usage, id }) => {
    const item = options?.find(({ id: selectedOptionId }) => id === selectedOptionId);
    if (!item) return acc;
    return [...acc, { type: usage?.priceType, sum: getMultipliedDeposit(usage?.price, item?.count || SLOT_COUNT.PREVIEW) }];
  }, [] as { type: PriceType, sum: BigNumber | null }[]);
  const foundSlots = slotsData.reduce((acc, { usage, id }) => {
    const item = slot?.id === id ? slot : null;
    if (!item) return acc;
    return [...acc, { type: usage?.priceType, sum: getMultipliedDeposit(usage?.price, item?.count || SLOT_COUNT.PREVIEW) }];
  }, [] as { type: PriceType, sum: BigNumber | null }[]);
  const groupedByType = [...foundOptions, ...foundSlots]
    .reduce((acc, { type, sum }) => ({ ...acc, [type]: getSumDeposit(acc[type], sum) }), {});
  return Object.entries(groupedByType).map(([type, sum]) => ({
    type: priceTypeMap[type],
    sum: `${getFixedDeposit({ deposit: sum as BigNumber })} TEE`,
  }));
};

export const getCountOfContent = (content?: Dataset | Model | Engine | Compute | null): number => {
  return ((content as Dataset)?.offerId ? 1 : 0)
    + ((content as Dataset)?.fileId ? 1 : 0)
    + ((content as Compute)?.teeOfferId ? 1 : 0);
};

export const getFormBuildOffersCount = (buildOrderForm: BuildOrderForm): number => {
  const {
    model, engine, datasets, compute,
  } = buildOrderForm;
  return getCountOfContent(model)
    + getCountOfContent(engine)
    + (datasets || []).map(getCountOfContent).reduce((acc, item) => acc + item, 0)
    + getCountOfContent(compute);
};

export const getSlotsFromTeeOffersAndSLots = (data?: TeeOffersAndSLots): Slots | null => {
  if (!data) return null;
  const { slotResult, optionsResult } = data || {};
  const { optionResults } = optionsResult || {};
  const { multiplier, slot } = slotResult || {};
  const { id } = slot || {};
  return {
    slot: {
      id,
      count: multiplier,
    },
    options: (optionResults || []).map(({ id, count }) => ({ id, count })),
  };
};

export const getRequirements = (value?: ExtendedModel | ExtendedDataset | ExtendedEngine | ExtendedCompute): Requirement[] => {
  if (!value) return [];
  const { offer, slots } = value as ExtendedModel | ExtendedDataset | ExtendedEngine;
  const { teeOffer } = value as ExtendedCompute;
  if (offer && slots) {
    const selectedSlot = (offer?.slots || []).find(({ id }) => id === slots.slot?.id);
    return [...convertSlotInfo(selectedSlot?.info), ...convertOptionData(selectedSlot?.option ? [selectedSlot.option] : [])];
  }
  if (teeOffer && slots) {
    const selectedSlot = (teeOffer?.slots || []).find(({ id }) => id === slots.slot?.id);
    const selectedOptions = (teeOffer?.options || [])
      .filter(({ id }) => slots.options?.map((option) => option?.id)?.includes(id));
    return [...convertSlotInfo(selectedSlot?.info), ...convertOptionData(selectedOptions.map((option) => option?.info))];
  }
  return [];
};

export const getPrice = (value?: ExtendedModel | ExtendedDataset | ExtendedEngine | ExtendedCompute): Requirement[] => {
  if (!value) return [];
  const { offer, slots } = value as ExtendedModel | ExtendedDataset | ExtendedEngine;
  const { teeOffer } = value as ExtendedCompute;
  if (offer && slots) {
    return getOfferPriceBySlots(slots, offer?.slots).map(({ type, sum }) => ({ label: type, value: sum }));
  }
  if (teeOffer && slots) {
    return getOfferPriceBySlots(slots, teeOffer?.slots, teeOffer?.options).map(({ type, sum }) => ({ label: type, value: sum }));
  }
  return [];
};

export const getName = (value?: ExtendedModel | ExtendedDataset | ExtendedEngine | ExtendedCompute): string => {
  if (!value) return '';
  const {
    offerId, offer,
  } = value as ExtendedModel | ExtendedDataset | ExtendedEngine;
  const { fileId, file } = value as ExtendedModel | ExtendedDataset;
  const { teeOfferId, teeOffer } = value as ExtendedCompute;
  if (offerId) {
    return offer?.offerInfo?.name || '';
  }
  if (fileId) {
    return file?.name || '';
  }
  if (teeOfferId) {
    return teeOffer?.teeOfferInfo?.name || '';
  }
  return '';
};

export const getOfferDeposit = (
  slots?: Slots | null,
  slotsData?: OfferSlot[],
  lease?: number,
): Deposit | null => {
  const { slot } = slots || {};
  const { id } = slot || {};
  if (!id) return null;
  const slotsDataBySlotId = (slotsData || []).find(({ id: slotId }) => slotId === id);
  if (!slotsDataBySlotId) return null;
  const { usage } = slotsDataBySlotId;
  return getDeposit({
    slotUsage: usage, lease, count: 1,
  });
};

export const getTEEOfferDeposit = (
  slots?: Slots | null,
  slotsData?: TeeOfferSlot[],
  optionsData?: TeeOfferOption[],
  lease?: number,
): Deposit | null => {
  const { slot, options = [] } = slots || {};
  const { id, count } = slot || {};
  if (!id) return null;
  const slotsDataBySlotId = (slotsData || []).find(({ id: slotId }) => slotId === id);
  const { usage } = slotsDataBySlotId || {};
  const optionsDataByOptionsIds = options?.length
    ? (optionsData || []).filter(({ id }) => options.find((option) => option?.id === id))
    : [];
  const depositSlots = getDeposit({
    slotUsage: usage, lease, count,
  });
  const depositOptions = optionsDataByOptionsIds.map(({ id, usage }) => {
    const count = options?.find((option) => option?.id === id)?.count ?? 0;
    return getDeposit({
      slotUsage: usage,
      lease,
      count,
    });
  });
  return getMultipleDeposits([depositSlots, ...depositOptions]);
};

export const getModelDeposit = (model?: ExtendedModel | null, lease = 0): Deposit | null => {
  if (!model) return null;
  return getOfferDeposit(model.slots, model.offer?.slots, lease);
};

export const getEngineDeposit = (engine?: ExtendedEngine | null, lease = 0): Deposit | null => {
  if (!engine) return null;
  return getOfferDeposit(engine.slots, engine.offer?.slots, lease);
};

export const getDatasetDeposit = (dataset?: ExtendedDataset | null, lease = 0): Deposit | null => {
  if (!dataset) return null;
  return getOfferDeposit(dataset.slots, dataset.offer?.slots, lease);
};

export const getComputeDeposit = (compute?: ExtendedCompute | null, lease = 0): Deposit | null => {
  if (!compute) return null;
  return getTEEOfferDeposit(compute.slots, compute.teeOffer?.slots, compute.teeOffer?.options, lease);
};

export const getFormContentDeposit = (extendedFormContent?: ExtendedFormContent, lease = 0): Deposit | null => {
  if (!extendedFormContent) return null;
  const {
    model, datasets, engine, compute,
  } = extendedFormContent;
  return getMultipleDeposits([
    getComputeDeposit(compute, lease),
    getEngineDeposit(engine, lease),
    getModelDeposit(model, lease),
    ...(datasets || []).map((dataset) => getDatasetDeposit(dataset, lease)),
  ]);
};

export const calcDeposit = (deposit?: Deposit | null, lease = 0, minDeposit = '0'): BigNumber | null => {
  const sum = getSumDeposit(deposit?.fixed, lease ? deposit?.perHourByLease : 0);
  if (!sum) return new BigNumber(minDeposit);
  return sum.isLessThanOrEqualTo(minDeposit) ? new BigNumber(minDeposit) : sum;
};

export const getValueOfferWithSlotsAndOptions = (data?: Model | Dataset | Engine | null): ValueOfferWithSlotsAndOptions => {
  const { offerId, slots } = data || {};
  const { slot } = slots || {};
  const { id: slotId } = slot || {};
  return {
    offerId: offerId || '',
    slot: {
      id: slotId ?? '',
    },
  };
};

export const getValueOfferWithSlotsAndOptionsCompute = (data?: Compute | null): TeeOfferWithSlotsAndOptions => {
  const { teeOfferId, slots } = data || {};
  const { slot, options = [] } = slots || {};
  const { id: slotId, count: slotCount } = slot || {};
  return {
    offerId: teeOfferId || '',
    slot: {
      id: slotId ?? '',
      count: slotCount ?? 0,
    },
    options: options ?? [],
  };
};

export const getWorkflowConfigurationValidation = (
  buildOrderForm: BuildOrderForm,
): WorkflowConfigurationValidation => {
  const {
    model, datasets, compute, engine, lease,
  } = buildOrderForm;
  const MINUTES_IN_HOURS = 60;
  const minTimeMinutes = (lease || 0) * MINUTES_IN_HOURS;
  return {
    solution: engine ? [getValueOfferWithSlotsAndOptions(engine)] : [],
    storage: getValueOfferWithSlotsAndOptions(getConfig().NEXT_PUBLIC_STORAGE),
    data: (datasets ?? []).concat(model ? [model] : []).map(getValueOfferWithSlotsAndOptions),
    tee: getValueOfferWithSlotsAndOptionsCompute(compute),
    minTimeMinutes,
  };
};

export const getDepositFromForm = (extendedBuildOrderForm: ExtendedFormBuildOrderForm): string | null => {
  const { lease } = extendedBuildOrderForm;
  if (!lease) throw new Error('Lease required');
  return calcDeposit(getFormContentDeposit(extendedBuildOrderForm, lease), lease)?.toString() || null;
};

export const getSelectedOfferSlot = (
  data?: ExtendedModel | ExtendedDataset | ExtendedEngine | null,
  offer?: Offer | null,
): OfferSlot | null => {
  if (!data || !offer) return null;
  if (data.offerId === offer.id && offer.slots?.length) {
    return offer.slots?.find((slot) => slot?.id === data.slots?.slot?.id) || null;
  }
  return null;
};

export const getSlotPrice = (
  offer?: Offer | null,
  priceType?: PriceType,
  formContent?: FormContent,
  field?: FieldsBuildOrderForm.datasets | FieldsBuildOrderForm.model | FieldsBuildOrderForm.engine,
): null | string => {
  if (!offer?.slots || !priceType || !formContent || !field) return null;
  const formOffersByField = formContent[field];
  const selectedSlot = Array.isArray(formOffersByField)
    ? formOffersByField
      .map((formOffer) => getSelectedOfferSlot(formOffer, offer))
      .find((value) => value)
    : getSelectedOfferSlot(formOffersByField, offer);
  if (selectedSlot) {
    return selectedSlot.usage?.priceType === priceType ? selectedSlot.usage?.price : null;
  }
  return getMinPriceFromSlots(offer?.slots, priceType);
};

export const compareLeaseWithMinMaxTime = (
  lease?: number | null,
  minTimeMinutes?: number | null,
  maxTimeMinutes?: number | null,
): boolean => {
  if (!lease) return false;
  return (!minTimeMinutes || lease >= minTimeMinutes) && (!maxTimeMinutes || lease <= maxTimeMinutes);
};

export const checkMinMaxTime = (
  data?: ExtendedModel | ExtendedDataset | ExtendedEngine | null,
  lease?: number | null,
): null | Offer => {
  if (!data) return null;
  const { offerId, offer, slots } = data;
  if (!offerId) return null;
  if (!offer) throw new Error('Offer information required');
  const slot = getSlotFromOffer(offer, slots?.slot?.id);
  if (!slot) throw new Error('Slot required');
  if (compareLeaseWithMinMaxTime(lease, slot?.usage?.minTimeMinutes, slot?.usage?.maxTimeMinutes)) {
    return null;
  }
  return offer;
};

export const checkComputeMinMaxTime = (
  data?: ExtendedCompute | null,
  lease?: number | null,
): null | TeeOffer => {
  if (!data) return null;
  const { teeOffer, teeOfferId, slots } = data;
  if (!teeOfferId) return null;
  if (!teeOffer) throw new Error('TEE Offer information required');
  const slot = getSlotFromTEEOffer(teeOffer, slots?.slot?.id);
  if (!slot) throw new Error('Slot required');
  const optionsIds = (slots?.options || []).map(({ id }) => id);
  const options = getOptionsFromTEEOffer(teeOffer, optionsIds);
  if (
    compareLeaseWithMinMaxTime(lease, slot?.usage?.minTimeMinutes, slot?.usage?.maxTimeMinutes)
    && options.every((option) => compareLeaseWithMinMaxTime(lease, option?.usage?.minTimeMinutes, option?.usage?.maxTimeMinutes))
  ) {
    return null;
  }
  return teeOffer;
};

export const checkBuildOrderMinMaxTime = (
  extendedFormBuildOrderForm: ExtendedFormBuildOrderForm,
): CheckBuildOrderMinMaxTimeResult => {
  const MINUTES_IN_HOURS = 60;
  const {
    model, lease, datasets, engine, compute,
  } = extendedFormBuildOrderForm;
  const leaseInMinutes = (lease || 0) * MINUTES_IN_HOURS;

  return {
    model: [checkMinMaxTime(model, leaseInMinutes)],
    datasets: (datasets || []).map((dataset) => checkMinMaxTime(dataset, leaseInMinutes)),
    engine: [checkMinMaxTime(engine, leaseInMinutes)],
    compute: [checkComputeMinMaxTime(compute, leaseInMinutes)],
  };
};

export const fieldDescription = {
  [FieldsBuildOrderForm.model]: 'Model',
  [FieldsBuildOrderForm.datasets]: 'Datasets',
  [FieldsBuildOrderForm.engine]: 'Engine',
  [FieldsBuildOrderForm.compute]: 'Compute',
};