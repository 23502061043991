'use client';

import { useAppSelector } from 'lib/hooks';
import { useCallback } from 'react';
import { useRouter } from 'next/navigation';
import { Providers as OAuth2Providers } from 'lib/features/authOAuth2/types';
import { Providers as SecretKeeperProviders } from 'lib/features/secretKeeper/types';
import { balanceBigNumberSelector as balanceBigNumberSpProvideSelector } from 'lib/features/spProvider/selectors';
import { loadingBalanceSelector as loadingBalanceSpProviderSelector } from 'lib/features/spProvider';
import { BalanceBigNumber as BalanceSpProvider } from 'lib/features/spProvider/types';
import { User } from 'hooks/user/types';
import { loadingBalanceSelector as loadingBalanceWalletSelector } from 'lib/features/wallet';
import { balanceBigNumberSelector as balanceBigNumberWalletSelector } from 'lib/features/wallet/selectors';
import { BalanceBigNumber as BalanceWallet } from 'lib/features/wallet/types';
import { useAuthSPProvider, UseAuthSPProvider } from './useAuthSPProvider';
import { useAuthSecretKeeper, UseAuthSecretKeeper } from './useAuthSecretKeeper';

export interface UseAuthResult {
  provider: UseAuthSPProvider['provider'] | UseAuthSecretKeeper['provider'];
  isUserConnected: boolean;
  balance: BalanceWallet | BalanceSpProvider;
  loadingBalance?: boolean;
  loading: boolean;
  user?: User | null;
  login: (provider: OAuth2Providers | SecretKeeperProviders) => Promise<void>;
  logout: () => Promise<void>;
  updateCurrentUser: UseAuthSPProvider['updateCurrentUser'] | UseAuthSecretKeeper['updateCurrentUser'];
  updateCurrentUserByProvider: (provider?: OAuth2Providers | SecretKeeperProviders) =>
    UseAuthSPProvider['updateCurrentUser'] | UseAuthSecretKeeper['updateCurrentUser'];
  isProviderSPProvider: boolean;
  isProviderSecretKeeper: boolean;
  getIsNewProviderSPProvider: (provider: unknown) => boolean;
  getIsNewProviderSecretKeeper: (provider: unknown) => boolean;
}

export const useAuth = (): UseAuthResult => {
  const router = useRouter();

  const {
    login: loginSPProvider,
    logout: logoutSPProvider,
    isProvider: isProviderSPProvider,
    getIsNewProvider: getIsNewProviderSPProvider,
    isAuthChecked: isAuthCheckedOAuth2,
    updateCurrentUser: updateCurrentUserSPProvider,
    ...spProvider
  } = useAuthSPProvider();

  const {
    login: loginSecretKeeper,
    logout: logoutSecretKeeper,
    isProvider: isProviderSecretKeeper,
    getIsNewProvider: getIsNewProviderSecretKeeper,
    isAuthChecked: isAuthCheckedSecretKeeper,
    updateCurrentUser: updateCurrentUserSecretKeeper,
    ...secretKeeper
  } = useAuthSecretKeeper();

  const provider = spProvider.provider || secretKeeper.provider;

  const login = useCallback((provider: SecretKeeperProviders | OAuth2Providers) => {
    if (getIsNewProviderSPProvider(provider)) {
      return loginSPProvider(provider as OAuth2Providers);
    }
    if (getIsNewProviderSecretKeeper(provider)) {
      return loginSecretKeeper(provider as SecretKeeperProviders);
    }
    throw new Error('Provider not defined');
  }, [loginSPProvider, loginSecretKeeper, getIsNewProviderSecretKeeper, getIsNewProviderSPProvider]);

  const logout = useCallback(async () => {
    if (isProviderSPProvider) {
      await logoutSPProvider();
    }
    if (isProviderSecretKeeper) {
      await logoutSecretKeeper();
    }
    router.replace('/');
  }, [isProviderSPProvider, isProviderSecretKeeper, logoutSPProvider, logoutSecretKeeper, router]);

  const updateCurrentUser = useCallback(() => {
    if (isProviderSPProvider) {
      return updateCurrentUserSPProvider(null);
    }
    if (isProviderSecretKeeper) {
      return updateCurrentUserSecretKeeper(null);
    }
    throw new Error('Provider not defined');
  }, [isProviderSPProvider, isProviderSecretKeeper, updateCurrentUserSPProvider, updateCurrentUserSecretKeeper]);

  const updateCurrentUserByProvider = useCallback((provider?: OAuth2Providers | SecretKeeperProviders) => {
    if (getIsNewProviderSPProvider(provider)) {
      return updateCurrentUserSPProvider;
    }
    if (getIsNewProviderSecretKeeper(provider)) {
      return updateCurrentUserSecretKeeper;
    }
    throw new Error('Provider not defined');
  }, [getIsNewProviderSPProvider, updateCurrentUserSPProvider, getIsNewProviderSecretKeeper, updateCurrentUserSecretKeeper]);

  const balanceWallet = useAppSelector(balanceBigNumberWalletSelector);
  const balanceSpProvider = useAppSelector(balanceBigNumberSpProvideSelector);
  const loadingBalanceWallet = useAppSelector(loadingBalanceWalletSelector);
  const loadingBalanceSpProvider = useAppSelector(loadingBalanceSpProviderSelector);

  return {
    balance: isProviderSPProvider ? balanceSpProvider : balanceWallet,
    provider,
    isUserConnected: spProvider.isUserConnected || secretKeeper.isUserConnected,
    loading: !isAuthCheckedSecretKeeper || !isAuthCheckedOAuth2 || spProvider.loading || secretKeeper.loading,
    loadingBalance: loadingBalanceWallet || loadingBalanceSpProvider,
    user: spProvider.user || secretKeeper.user,
    isProviderSPProvider,
    isProviderSecretKeeper,
    login,
    logout,
    updateCurrentUser,
    updateCurrentUserByProvider,
    getIsNewProviderSPProvider,
    getIsNewProviderSecretKeeper,
  };
};