import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'connectors/secretkeeper';
import {
  User,
  TokenResponse,
  File,
  PostFileProps,
  PatchFileProps,
  PostFileResult,
  UserSettings,
  PostStorage,
  Storage,
} from './types';
import {
  setUser, resetState as resetStateAuth, setAccessToken,
} from '.';
import { baseQueryWithRefresh } from './baseQueryWithRefresh';

export const api = createApi({
  reducerPath: 'secretKeeperApi',
  baseQuery: baseQueryWithRefresh(baseQuery),
  tagTypes: ['secretKeeper'],
  endpoints: (builder) => ({
    logout: builder.mutation<void, void>({
      query() {
        return {
          url: 'auth/logout',
          credentials: 'include',
        };
      },
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        await queryFulfilled.catch((e) => e);
        dispatch(resetStateAuth());
      },
    }),
    signIn: builder.mutation<void, { address: string }>({
      query(body) {
        return {
          url: 'auth/sign-in',
          method: 'POST',
          body,
        };
      },
    }),
    signUp: builder.mutation<User, { address: string }>({
      query(body) {
        return {
          url: 'auth/sign-up',
          method: 'POST',
          body,
        };
      },
    }),
    getMe: builder.query<User, null>({
      query() {
        return {
          url: 'auth/me',
        };
      },
    }),
    getNonce: builder.query<{ nonce: string }, string>({
      query(address) {
        return {
          url: `users/nonce/${address}`,
        };
      },
    }),
    updateToken: builder.mutation<TokenResponse, { address: string, signature: string }>({
      query(body) {
        return {
          url: 'auth/token',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data?.user));
          dispatch(setAccessToken(data?.accessToken));
        } catch (e) {
          setUser(null);
          setAccessToken(null);
        }
      },
    }),
    updateMe: builder.query<User, null | undefined>({
      query() {
        return {
          url: 'auth/me',
        };
      },
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
        } catch (e) {
          setUser(null);
        }
      },
    }),
    getFile: builder.query<File, string>({
      query(file) {
        return {
          url: `files/${file}`,
        };
      },
    }),
    getStorage: builder.query<Storage, string>({
      query(storage) {
        return {
          url: `storages/${storage}`,
        };
      },
    }),
    getStorages: builder.query<Storage[], null>({
      query() {
        return {
          url: 'storages',
        };
      },
    }),
    postFile: builder.query<PostFileResult, PostFileProps>({
      query(body) {
        return {
          url: 'files',
          method: 'POST',
          body,
        };
      },
    }),
    deleteFile: builder.query<File, string>({
      query(id) {
        return {
          url: `files/${id}`,
          method: 'DELETE',
        };
      },
    }),
    patchFile: builder.query<File, PatchFileProps>({
      query({ body, id }) {
        return {
          url: `files/${id}`,
          method: 'PATCH',
          body,
        };
      },
    }),
    getFiles: builder.query<File[], null>({
      query() {
        return {
          url: 'files',
        };
      },
    }),
    getUserSettings: builder.query<UserSettings, null>({
      query() {
        return {
          url: 'user-settings',
        };
      },
    }),
    updateUserSettings: builder.query<UserSettings, Partial<UserSettings>>({
      query(body) {
        return {
          url: 'user-settings',
          method: 'PATCH',
          body,
        };
      },
    }),
    createUserSettings: builder.query<UserSettings, Partial<UserSettings>>({
      query(body) {
        return {
          url: 'user-settings',
          method: 'POST',
          body,
        };
      },
    }),
    createCentralizedStorage: builder.query<Storage, null>({
      query() {
        return {
          url: 'storages/centralized',
          method: 'POST',
        };
      },
    }),
    createStorage: builder.query<Storage, PostStorage>({
      query(body) {
        return {
          url: 'storages',
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const {
  useUpdateMeQuery,
  useLazyUpdateMeQuery,
  useLogoutMutation,
  useSignInMutation,
  useSignUpMutation,
  useLazyPostFileQuery,
  useLazyPatchFileQuery,
  useLazyDeleteFileQuery,
  useGetFileQuery,
  useGetFilesQuery,
  useLazyGetMeQuery,
  useLazyCreateUserSettingsQuery,
  useLazyGetUserSettingsQuery,
  useGetUserSettingsQuery,
  useLazyUpdateUserSettingsQuery,
  useLazyGetStoragesQuery,
  useLazyCreateCentralizedStorageQuery,
  useGetStoragesQuery,
  useGetStorageQuery,
  useLazyGetStorageQuery,
  useLazyCreateStorageQuery,
  useLazyGetFilesQuery,
  useLazyGetFileQuery,
} = api;